import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light", //"light" or "dark" //the default THEME
  base: "https://discountzap.online/api", //PRODUCTION 
  //base: "https://glitchgoat.com/api", //PRODUCTION 
  //base: "http://localhost:3001", //DEV 
  user: null,
  token: null,
  posts: [],
  blogposts: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setLogout: (state) => {
      state.user = null;
      state.token = null;
    },
    setFriends: (state, action) => {
      if (state.user) {
        state.user.friends = action.payload.friends;
      } /*else {
        console.log("no friends yet");
      }*/
    },
    setPosts: (state, action) => {
      state.posts = action.payload.posts;
    },
    setPost: (state, action) => {
      const updatedPosts = state.posts.map((post) => {
        if (post._id === action.payload.post._id) return action.payload.post;
        return post;
      });
      state.posts = updatedPosts;
    },
    setBlogposts: (state, action) => {
      state.blogposts = action.payload.blogposts;
    },
    setBlogpost: (state, action) => {
      const updatedPosts = state.blogposts.map((blogpost) => {
        if (blogpost._id === action.payload.blogpost._id) return action.payload.blogpost;
        return blogpost;
      });
      state.blogposts = updatedPosts;
    },
  },
});

export const { setMode, setLogin, setLogout, setFriends, setPosts, setPost, setBlogposts, setBlogpost } =
  authSlice.actions;
export default authSlice.reducer;
